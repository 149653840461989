<script setup>
import LanguageConverter from '@/util/LanguageConverter'
import InfoCardVue from '@/components/ui/InfoCard.vue'
import PlatformBoardListVue from '@/components/ui/PlatformBoardList.vue'
import BoardsNavigationVue from '@/components/Home/BoardsNavigation.vue'
import { getLabPaper, getLabPatent, getLabPjt, getTechHandover } from '@/api/platform/community'
import { ref, onMounted } from 'vue'
import { getManyLab } from '@/api/labs'
import { useRouter } from 'vue-router'

const router = useRouter()

const communityData = ref({
  pjt: [],
  paper: [],
  patent: [],
  handover: []
})
// 유망연구실 INFO 데이터
const showCommunityData = ref([])

const cardList = ref([])

const labInfoList = [
  { link: '/community/pjt', text: LanguageConverter({ en: 'Projects', kr: '프로젝트' }) },
  { link: '/community/paper', text: LanguageConverter({ en: 'Papers', kr: '논문' }) },
  { link: '/community/patent', text: LanguageConverter({ en: 'Patents', kr: '지재권' }) },
  { link: '/community/handover', text: LanguageConverter({ en: 'Tech Cooperations', kr: '기업협력' }) }
]
const labMenuList = [
  { link: '/researchField/result?q=sixTechnologyLarge=IT', text: LanguageConverter({ en: 'IT', kr: '정보기술' }) },
  { link: '/researchField/result?q=sixTechnologyLarge=BT', text: LanguageConverter({ en: 'BT', kr: '생명공학기술' }) },
  { link: '/researchField/result?q=sixTechnologyLarge=NT', text: LanguageConverter({ en: 'NT', kr: '나노기술' }) },
  { link: '/researchField/result?q=sixTechnologyLarge=ST', text: LanguageConverter({ en: 'ST', kr: '우주항공기술' }) },
  { link: '/researchField/result?q=sixTechnologyLarge=ET', text: LanguageConverter({ en: 'ET', kr: '환경기술' }) }
]

const mapPjtData = (item) => {
  return {
    link: `/community/pjt/${item.projectId}`,
    textContents: {
      title: LanguageConverter({ en: item.projectTitleEn, kr: item.projectTitleKr }),
      subtitle: [
        `(${item.projectNumber})`,
        `${item.projectYear ? new Date(item.projectYear).getFullYear() : ''}`,
        item.projectResearchAgency,
        item.projectMinistry
      ],
      lab: LanguageConverter({ en: item.labNameEn, kr: item.labName })
    }
  }
}
const mapPaperData = (item) => {
  return {
    link: `/community/paper/${item.paperId}`,
    textContents: {
      title: item.paperResultTitle,
      subtitle: [
        `(${item.paperResultID})`, //
        `${item.paperPubYear ? new Date(item.paperPubYear).getFullYear() : ''}`,
        item.paperJCType,
        item.paperJournalName
      ],
      lab: LanguageConverter({ en: item.labNameEn, kr: item.labName })
    }
  }
}
const mapPatentData = (item) => {
  return {
    link: `/community/patent/${item.patentId}`,
    textContents: {
      title: item.patentResultTitle,
      subtitle: [
        `(${item.patentResultID})`, //
        `${item.patentYear ? new Date(item.patentYear).getFullYear() : ''}`,
        item.patentRegistType,
        item.patentIprType,
        item.patentRegistrant
      ],
      lab: LanguageConverter({ en: item.labNameEn, kr: item.labName })
    }
  }
}
const mapHandoverData = (item) => {
  return {
    link: `/community/handover/${item.handoverId}`,
    textContents: {
      title: LanguageConverter({ en: item.techNameEn, kr: item.techName }),
      subtitle: [
        `${item.techTransferYear ? new Date(item.techTransferYear).getFullYear() : ''}`, //
        item.techGrowLevel,
        item.techStatus,
        item.techApplyField
      ],
      lab: LanguageConverter({ en: item.labNameEn, kr: item.labName })
    }
  }
}

const setCommunityData = async () => {
  communityData.value.pjt = (await getLabPjt({ limit: 5 })).items.map(mapPjtData)
  communityData.value.paper = (await getLabPaper({ limit: 5 })).items.map(mapPaperData)
  communityData.value.patent = (await getLabPatent({ limit: 5 })).items.map(mapPatentData)
  communityData.value.handover = (await getTechHandover({ limit: 5 })).items.map(mapHandoverData)
}
const setLabData = async (sixTechnoloyLargeCode = 'IT') => {
  const result = await getManyLab({ sixTechnologyLarge: sixTechnoloyLargeCode, limit: 9 })

  if (result.code === 'DB_DATA_NOT_FOUND') {
    cardList.value = null
    return
  }

  cardList.value = result.items.map((item) => {
    return {
      src: item.leadResearcherImgUrl,
      data: {
        title: LanguageConverter({ en: item.labNameEn, kr: item.labName }),
        subtitle: LanguageConverter({ en: item.leadResearcherNameEn, kr: item.leadResearcherName }),
        infoList: [
          {
            head: LanguageConverter({ en: 'Position', kr: '직위' }),
            desc: LanguageConverter({ en: item.leadResearcherPosition, kr: item.leadResearcherPosition })
          },
          {
            head: LanguageConverter({ en: 'Institution', kr: '소속기관' }),
            desc: LanguageConverter({ en: item.affiliatedInstitutionName, kr: item.affiliatedInstitutionName })
          },
          {
            head: LanguageConverter({ en: 'MainField', kr: '분야' }),
            desc: LanguageConverter({ en: item.sixTechnologyMedium, kr: item.sixTechnologyMedium })
          },
          {
            head: LanguageConverter({ en: 'Keywords', kr: '키워드' }),
            desc: LanguageConverter({
              en: item.keywordEn
                ?.split(',')
                .slice(0, 3)
                .reduce((acc, cur) => acc + ',' + cur),
              kr: item.keyword
                ?.split(',')
                .slice(0, 3)
                .reduce((acc, cur) => acc + ',' + cur)
            })
          }
        ],
        link: `/lab/${item.labNo}`
      }
    }
  })
}

const replaceCommunitData = (item) => {
  const link = item.dataset.link

  switch (link) {
    case '/community/pjt':
      showCommunityData.value = communityData.value.pjt
      break
    case '/community/paper':
      showCommunityData.value = communityData.value.paper
      break
    case '/community/patent':
      showCommunityData.value = communityData.value.patent
      break
    case '/community/handover':
      showCommunityData.value = communityData.value.handover
      break

    default:
      break
  }
}
const replaceLabData = async (item) => {
  const sixTechnoloyLargeCode = item.dataset.link.split('=').at(-1)
  await setLabData(sixTechnoloyLargeCode)
}
const cardClickHandler = (data) => {
  const { link } = data
  if (link) router.push(link)
}

onMounted(async () => {
  // isLoading.value = true
  await setCommunityData()
  await setLabData()

  showCommunityData.value = communityData.value.pjt

  // isLoading.value = false
})
</script>

<template>
  <section class="wf__search-view-section">
    <article class="info-wrapper">
      <div class="title-wrapper">
        <h3 class="title">{{ LanguageConverter({ en: 'Promising', kr: '유망연구실' }) }} INFORMATION</h3>
      </div>

      <BoardsNavigationVue :menuItemList="labInfoList" @onItemClick="replaceCommunitData" />
      <PlatformBoardListVue :boardList="showCommunityData" />
    </article>

    <article class="lab-article">
      <section class="lab-wrapper">
        <div class="title-wrapper">
          <h3 class="title">Professionals</h3>
          <h5 class="wf__subtitle">이달에 주목할 연구실</h5>
        </div>

        <BoardsNavigationVue :menuItemList="labMenuList" @onItemClick="replaceLabData" />
      </section>

      <section class="lab-cards-wrapper">
        <div class="lab-card-item wf__card" v-for="(card, i) in cardList" :key="i">
          <InfoCardVue :src="card.src" :data="card.data" @onClick="cardClickHandler" />
        </div>
      </section>
    </article>
  </section>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.info-wrapper {
  min-height: 270px;
}
.wf__search-view-section {
  display: flex;
  flex-direction: column;
  gap: 4em;
}
.title-wrapper {
  display: flex;
  align-items: flex-end;
  gap: 1em;
}
.title-wrapper .title {
  font-weight: bold;
  font-size: var(--wf-text-24);
}
.title-wrapper .wf__subtitle {
  font-size: var(--wf-text-16);
  color: var(--wf-secondary-color);
}

.info-board-wrapper {
  margin-top: 0.5em;
}
.info-board-wrapper li {
  padding: 0.25em 0.5em;
  display: flex;
}
.info-board-wrapper li span:nth-child(1) {
  flex: 5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
.info-board-wrapper li span:nth-child(2) {
  text-align: right;
  flex: 2;
}
.lab-article {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  min-height: 600px;
}

.lab-article .lab-cards-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  column-gap: 1em;
  row-gap: 2em;
  font-size: var(--wf-text-16);
}
.lab-card-item {
  overflow-x: hidden;
  max-width: 100%;
  width: 100%;
}

:deep(.wf__board-list li) {
  font-size: var(--wf-text-16);
}
@media screen and (max-width: 992px) {
  .lab-article .lab-cards-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .lab-article .lab-cards-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
